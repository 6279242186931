import { onScopeDispose, Ref, ref } from "vue";

interface MediaQueryInstance {
  uses: number;
  ref: Readonly<Ref<boolean>>;
  cleanup: () => void;
}

const cache = new Map<string, MediaQueryInstance>();

export const useMediaQuery = (condition: string): Readonly<Ref<boolean>> => {
  let instance = cache.get(condition);
  if (instance === undefined) {
    const query = matchMedia(condition);
    const match = ref(query.matches);

    query.onchange = () => (match.value = query.matches);

    instance = {
      uses: 0,
      ref: match,
      cleanup() {
        if (--instance!.uses < 1) {
          query.onchange = null;
          cache.delete(condition);
        }
      },
    };

    cache.set(condition, instance);
  }

  instance.uses++;
  onScopeDispose(instance.cleanup);

  return instance.ref;
};
